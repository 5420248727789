import React, { Component } from 'react';
import moment from 'moment/moment';

import OG from '../printer-components/blogpost';
import logo from '../../static/img/mail/icon.svg';
import Image from '../../static/blogs/5mintest/cover.svg';
import docsimg from '../../assets/images/overview-dark.png';

let Img = () => <img src={image} />;

class Page extends Component {
  render() {
    return (
      <div>
        <OG
          title="Polypane 23"
          prefix="posts"
          logo={logo}
          imageType="svg"
          image={Image}
          date="Jan 16th, 2025"
        />
      </div>
    );
  }
}

export default Page;


// <OGr
// title="Polypane Portal"
// prefix="docs"
// logo={logo}
// imageType="png"
// image={() => <img src={docsimg} />}
// />
